// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or any plugin's vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.

// IE polyfills
require('../polyfills')

require('../asset_src.js')

// Bootstrap JS Component Additions
require('bootstrap-sass')

// Loading Progress bar
require('pace')

// Transforms any div into a scrollable area with a nice scrollbar
require('jquery-slimscroll')

// require('jquery-ui')

// jQuery Signatures
require('../libs/jquery-ui.min')
require('../libs/jquery.signature.min')

// CSRF Tokens
require('../init')

// Just for the <%= react_component %> erb helper method. 
require('react_ujs')

// View Helper Functions attached to window.utils

var React = window.React = require('react')
var ReactDOM = window.ReactDOM = require('react-dom')

import $ from 'expose-loader?exposes=$,jQuery!jquery'
import { createStore } from 'redux';
import rootReducer from '../reducers';

if(typeof window != 'undefined'){
  window.store = createStore(rootReducer, typeof initialStore !== 'undefined' && initialStore || {});
  window.store.dispatch({type: 'CHANGE_SENT_REFERRAL_TAB', tab_key: '#default'})

  window.Admin             = require('../components/Admin');
  window.IdleTimer         = require('../components/IdleTimer');
  window.LoginForm         = require('../components/LoginForm');
  window.MobileAttach      = require('../components/MobileAttach');
  window.ModeReports       = require('../components/ModeReports');
  window.PasswordResetForm = require('../components/PasswordResetForm');
  window.SentReferrals     = require('../components/SentReferrals');
  window.SentReferral      = require('../components/SentReferral');
  window.SignUpForm        = require('../components/SignUpForm');
  window.ToastVideo        = require('../components/ToastVideo');
  window.TokenForm         = require('../components/TokenForm');
  window.TopNavbarMenu     = require('../components/TopNavbarMenu');
  window.ProviderFilters   = require('../components/ProviderFilters');
  window.ProviderBrochure  = require('../components/ProviderBrochure');
  window.Reports           = require('../components/Reports');
  window.Sidebar           = require('../components/Sidebar');
  window.User              = require('../components/User');
  window.Users             = require('../components/Users');
} else {
  var store = createStore(rootReducer, typeof initialStore !== 'undefined' && initialStore || {});
  store.dispatch({type: 'CHANGE_SENT_REFERRAL_TAB', tab_key: '#default'})

  var SentReferral = require('../components/SentReferral')
}

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')

ReactRailsUJS.useContext(componentRequireContext)
